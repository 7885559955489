.term .droparea {
    border-radius: 8px;
    padding: 16px;
}

.year {
    background-color: aliceblue;
    margin: 8px auto;
}

.term {
    background-color: antiquewhite;
    border-radius: 8px;
}